import React from "react";
import { graphql } from "gatsby";
import Page from "./page";
import Offer from "../components/Offer/offer";
import ProjectExamples from "../components/ProjectExamples/projectExample";
import Seo from "../components/seo";
import useFlags from "../components/useFlags";

const Services = ({ path, location, data: { mdx } }) => {
  const { show_offer, show_project_example } = useFlags();
  return (
    <>
      <Seo
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        pathname={path}
      />
      <div className="mb-5">
        <Page location={location} mdx={mdx} />
        {show_offer && <Offer />}
        {show_project_example && <ProjectExamples />}
      </div>
    </>
  );
};
export default Services;

export const query = graphql`
  query Services($locale: String!, $slug: String!) {
    mdx(fields: { locale: { eq: $locale }, slug: { eq: $slug } }) {
      frontmatter {
        title
        description
      }
      body
    }
  }
`;
